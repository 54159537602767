import {Category, LandingPage, Product} from "@shopware-pwa/types";

export function getProductCacheKeys (product: Product) {
    if(product && product.hasOwnProperty('customFields')){
        return product.customFields?.cacheTags ?? []
    }

    return []
}

export function getLandingPageCacheKeys (category: LandingPage) {
    if(category && category.hasOwnProperty('customFields')){
        return category.customFields?.cacheTags ?? []
    }

    return []
}

export function getCategoryCacheKeys (category: Category) {
    if(category && category.hasOwnProperty('customFields')){
        return category.customFields?.cacheTags ?? []
    }

    return []
}